import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/vercel/workpath0/src/components/layout.js";
import { graphql, Link } from "gatsby";
import Layout from '../../../../components/layout';
import SEO from '../../../../components/seo';
import { IntroProject, Intro, IntroImage } from '../../../../components/Intro/Intro';
import { Section, Container, CSSGrid, Col } from '../../../../components/Grid';
import { MainProject, CardStaticImg, CardLink, CardContainer, CardBody } from '../../../../components/Card';
import { ButtonGhost } from '../../../../components/Button';
import { designTokens } from '../../../../components/Theme/designTokens';
import projects from '../../../../data/projects';
import { IconBox, IconCard } from '../../../../components/IconBox';
import { Box } from '../../../../components/Box';
import { Text } from '../../../../components/Text';
import { OutboundLink } from '../../../../components/StyledLink';
import { CallToAction } from '../../../../components/CallToAction';
import MdPerson from 'react-ionicons/lib/MdPerson';
import MdFlag from 'react-ionicons/lib/MdFlag';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <SEO title="Chargebacks911" mdxType="SEO" />
    <IntroProject projectName="Email Signature Generator" projectDescription="An interface to create dynamic, conditional email signatures" projectYear="2018" projectImage="cb911-email-preview" subProject="Chargebacks911" subProjectLink="chargebacks911" mdxType="IntroProject" />
    <Section mdxType="Section">
  <Container mdxType="Container">
    <CSSGrid mdxType="CSSGrid">
      <Col col={1 / 8} media={{
            phone: 1
          }} mdxType="Col"></Col>
      <Col col={6 / 8} media={{
            phone: 1
          }} mdxType="Col">
        <h5>Overview 📋</h5>
        <p>In my refactor of the company's email signature, we also needed to have working, custom signatures for the sales team. I quickly realized how timely and repetitive of a process it was to code custom signatures for sales team members manually.</p>
        <p>Each sales team member required various, conditional values in their signature:</p>
        <Box marginBottom={designTokens.space[4]} mdxType="Box">
          <ul>
            <li>The team member's profile picture</li>
            <li>The team member's home office</li>
            <li>The team member's mobile phone</li>
            <li>The team member's meeting link</li>
            <li>The team member's extension line</li>
          </ul>
        </Box>
        <p>In a bit of my free time, I decided to create a small a Vue JS app that would help members of the sales team create their own Hubspot email signature.</p>
      </Col>
      <Col col={1 / 8} media={{
            phone: 1
          }} mdxType="Col"></Col>
    </CSSGrid>
  </Container>
    </Section>
    <CSSGrid mdxType="CSSGrid">
  <Col mdxType="Col">
    <Box center bg={({
          theme
        }) => theme.greenTransparent} marginBottom={designTokens.space[6]} paddingY={designTokens.space[6]} mdxType="Box">
      <Container mdxType="Container">
        <CSSGrid mdxType="CSSGrid">
          <Col mdxType="Col">
            <Text center mdxType="Text">Problem:</Text>
            <p className="lead">Could we give team members access to create their own email signatures but also remain consistent with the brand?</p>
          </Col>
        </CSSGrid>
      </Container>
    </Box>
  </Col>
    </CSSGrid>
    <Text center marginBottom={designTokens.space[3]} size={designTokens.fontSizes[0]} color={({
      theme
    }) => theme.grey500} mdxType="Text">Version 1</Text>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "960px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "50%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAIAAAA7N+mxAAAACXBIWXMAAAsSAAALEgHS3X78AAACB0lEQVQoz0WQT2sTQRjG5yJYBT+G4IdQ70JPHoUKUi+Fgv0GCq3EogcPKkrCxtJaSmvVSw+NNn/aZKMGCs0mkhCSdLN/szs7M5vJbpLNZpxdCb48DM8L85t33gd8Awulu4vq3qGcTDcTL5tv3rfeflCFLWNrWxM+6pHSRiTBFAQjlTKSSWN75+L2nSIA4BBcqTxe8xmjwcQiSMNYcRwFIWc0Chjjmsz1z/vT6Yyx1vKyGMNXy0sr2Peg2e+bZt8wuDRFsQ3Dp3RAXI9Sbvg59jxvMBC/Z9PCrrCydnTzFvgMFsSHq2QyRhBCG2KEgyAIw3AWhmw2I4TwljFu+TyGEM4eHdcvJD0IM883wT64Vo5hh5MY89en/GPz4rcxJpRS13XH4zEh7nEm2/hTR94w83Sdw9dLMQwty3GQ7/vevDjj2HaMR8Vby7Lz+WK9KpmEnDzb+A87EOq63ul0LuOSZRkihLptpGlDSicTHhZzHPy7cs6X5/5040UML62ikY9s2Ov1qlWpLtVqklSrSpbZdyyLQDjgczHmgEvcn+UKsizuC+uJaGfx0RPK2MAbqkqv2Wx0NbWt9GRdI743mk5pFEMwYVFgLvXO6w0eZgQnXoEDcCN370H3rNzO5Bs7u629fe3sl5ovaaeiWijJJ4XLkzw/lVyhXxTlH7kvm6+z71LFTwdfF+//BUsM6PsGHZtrAAAAAElFTkSuQmCC')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Chargebacks911 Email Signature Generator",
          "title": "Chargebacks911 Email Signature Generator",
          "src": "/static/89191a81ad4a88b09c1b0322bc4df0bc/d9199/cb911-email-1.png",
          "srcSet": ["/static/89191a81ad4a88b09c1b0322bc4df0bc/8ff5a/cb911-email-1.png 240w", "/static/89191a81ad4a88b09c1b0322bc4df0bc/e85cb/cb911-email-1.png 480w", "/static/89191a81ad4a88b09c1b0322bc4df0bc/d9199/cb911-email-1.png 960w", "/static/89191a81ad4a88b09c1b0322bc4df0bc/2cefc/cb911-email-1.png 1400w"],
          "sizes": "(max-width: 960px) 100vw, 960px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <br />
    <br />
    <Section mdxType="Section">
  <Container mdxType="Container">
    <CSSGrid mdxType="CSSGrid">
      <Col col={1 / 8} media={{
            phone: 1
          }} mdxType="Col"></Col>
      <Col col={6 / 8} media={{
            phone: 1
          }} mdxType="Col">
        <h5>Version 2 🎉</h5>
        <p>After seeing the increase in efficiency and consistency in creating new email signatures for onboarded team members, it was time to tackle the other formats for which their email signatures may be used.</p>
        <p>We needed to outline a few goals for a version 2 of the signature generator:</p>
        <Box marginBottom={designTokens.space[4]} mdxType="Box">
          <ul>
            <li>Add optional fields (e.g. Skype, Mobile, etc.) thay be be customized and toggled</li>
            <li>Build and test signature for Chrome, Outllok, and the plethora of email clients</li>
            <li>Build a more fluid, simple UI that provides better clarity in generating a signature</li>
          </ul>
        </Box>
        <p>Since this was a simple front-end application, I thought using Vue, jQuery, and any other heavy, third-party JS libraries may be unnecessary for such a simple project. Therefore, I opted to use simple, modern JavaScript to build out the interactivity of the signature generator</p>
      </Col>
      <Col col={1 / 8} media={{
            phone: 1
          }} mdxType="Col"></Col>
    </CSSGrid>
  </Container>
    </Section>
    <Text center marginBottom={designTokens.space[3]} size={designTokens.fontSizes[0]} color={({
      theme
    }) => theme.grey500} mdxType="Text">Version 2</Text>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "960px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "68.75%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAYAAAAvxDzwAAAACXBIWXMAAAsTAAALEwEAmpwYAAAC4klEQVQ4y3WTyU9TURTG+4dgGISWWqZSZEx04w7dKBg3gkghTEbdaURI1IXIIBI0ISRAoiFCNFFXKiQQAyxUEuiE1TJ1oEDte3Tua+n9PPdBojVwk1/ezR2+d853zlVMfLGia/QtHg1/IN79x3t0vniD+4MTxOSxdDyfZPeejaP71RRTLKy4YFj8iqVlA0xGo4zRwDHAbDKjquoKUlIyoFTmIDPz1JHQHks9kYFzldVMYdoQIVgM8Ho8iEajiMViiMfjSCQS4KOp+QZU2XnQFZWjkNAdQaGunGk0WlyovsYUyw4/1n5YYPtlg9X6E2aLBS7XFsS9Pfh8Puj1zchS5kJbWAqlKg+ZWRoi5xANreWiQFvC1Oo8VF6qYQqjKwi3y4H1jU04nU44HBwH7HYHtrd3UK9vIsEcWbCmVo+2tttobrmJFqK17Rbqrjfyvb+CBmcA21tO7OzuIhwOy2lHIhEZSZLQ0Ngi+1RUdhZLnz4iIXrhp+jBmGyJ0+lC0ekKlk2Rnq+qJQ+3Qgj6RFmA+3eU4EkSLC49g4WZWYieXUTox263Wz5rt9uh+1eQRygIv+H1+bEniggFg/KFpAjJK4oC374vwi8KEL1eBAIBuXBra+vJgsadKFwrJqzOz8FDoh46HIpKiMTikPb3kwR54eRxmC4fgiAkp7y8LsDa/xjGB+2wjQxh9eUIrEODsAz0wDY2jHoyPYsOF+rKcOduO3p6+9H1pBfd3X3oezqAjs6H0OrKmDqbC1JRTM4gglsOCFRhwb4J0XXwFTbW4aO5vqFZFuRVziAvU9NUSEtXIZWTpkR6hprappSdUucfCJrdYWpkCWEpRmkSEp9L8pynzAV5ytRr0HJIOJkS5BcUs2yKsPLiVd6HIYQCPuyRfz6O//BLBKhADY2tUKkL6EVU0OWyI6EXxDQ59FIu1zHFtC2CWVsAM7YgEUhiltZez69i5LMRY9NmjB4D7bGRKSPG51bZH+3KBxowzw8KAAAAAElFTkSuQmCC')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Chargebacks911 Email Signature Generator",
          "title": "Chargebacks911 Email Signature Generator",
          "src": "/static/782f4c38c67ffcf1845cafb94e083a00/d9199/cb911-email-2.png",
          "srcSet": ["/static/782f4c38c67ffcf1845cafb94e083a00/8ff5a/cb911-email-2.png 240w", "/static/782f4c38c67ffcf1845cafb94e083a00/e85cb/cb911-email-2.png 480w", "/static/782f4c38c67ffcf1845cafb94e083a00/d9199/cb911-email-2.png 960w", "/static/782f4c38c67ffcf1845cafb94e083a00/07a9c/cb911-email-2.png 1440w", "/static/782f4c38c67ffcf1845cafb94e083a00/29114/cb911-email-2.png 1920w", "/static/782f4c38c67ffcf1845cafb94e083a00/6159b/cb911-email-2.png 3220w"],
          "sizes": "(max-width: 960px) 100vw, 960px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <br />
    <br />
    <Text center marginBottom={designTokens.space[3]} size={designTokens.fontSizes[0]} color={({
      theme
    }) => theme.grey500} mdxType="Text">Change Themes</Text>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "960px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "64.99999999999999%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAYAAACpUE5eAAAACXBIWXMAAAsTAAALEwEAmpwYAAACc0lEQVQ4y32TzWsTURTFm1gXqW1mJs18JyTYpCCIBLLIRrBQg7hI1IVbtQr+De5c66ZFEKpF/AAFlxZFKKIrxaVNpI0o2HSaZGIzSZNJ8x2Pd4YgwZouDve9B+/Hufe8N6bV+tgmadWeXberfWSHpJnA89U13L2/gqWHT7H04MlfLS4/xr2VZ0hnd5HfB93tYUwbgllVN7somm0Ua5Za0KnG5+bgcoxhcmICTocDTqcDR8ePDDSOV+8+wugDW5XOEJCk1XrY3fqJsl6AWaui3WzCrNdx5UICJ1QZszMzkCSJJIPjPHC73bZW33+C0fsf0PyNfGod2c1NFHI5lMtlGIaB5MVLCM3OIhKJIBqNIhaLIRQOw+fzQVVVvP7wGaWDwD52yGE2l8dGJoNM5hvWUymk019xdn4eHo6DnwCCIIDneSiKYsNEURzh0Apkr4tSyYCu6ygWi6hUKrQvIZFI0GUfAoEgWAJb7bIsB4ZlwTDsqJat2kVBLyJHLi1Yq9VCo9FAMpkkJzLNTgEviORSgkhztM54/hCH2l7HdlglWJtgnU4bTQrGcuiVVQRDYfj9AciKzwZ7CTbt5Q9ruQOjXIFRq2GXArESrpPOE/CMX8Hp40FIigqfLIFjGFvs6JQHLRd0bLx8AS31Bb9oloV8Hgvn4rjGuHBTnsbVgIwb4SAW/CKu0/6y6MEbAh5MmQLZaQLZtbdI376FzcU7+PFoGd9Jc6dO4pjLBYFjIVIogscDgQIRyKGXcR/esjU/k1Sl92dWyvZ6np6Nk34EQ7BJatHSFMGmBuvRQDow6/toUBB1Snd/oHg8jnECcgRkCDSsf3/KH24D27+bQUkKAAAAAElFTkSuQmCC')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Dark Mode Toggle",
          "title": "Dark Mode Toggle",
          "src": "/static/4d4d5c4c7db8bb75c9c855cd85788996/d9199/cb911-email-3.png",
          "srcSet": ["/static/4d4d5c4c7db8bb75c9c855cd85788996/8ff5a/cb911-email-3.png 240w", "/static/4d4d5c4c7db8bb75c9c855cd85788996/e85cb/cb911-email-3.png 480w", "/static/4d4d5c4c7db8bb75c9c855cd85788996/d9199/cb911-email-3.png 960w", "/static/4d4d5c4c7db8bb75c9c855cd85788996/07a9c/cb911-email-3.png 1440w", "/static/4d4d5c4c7db8bb75c9c855cd85788996/29114/cb911-email-3.png 1920w", "/static/4d4d5c4c7db8bb75c9c855cd85788996/a2b91/cb911-email-3.png 3360w"],
          "sizes": "(max-width: 960px) 100vw, 960px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <br />
    <br />
    <Section mdxType="Section">
  <Container mdxType="Container">
    <CSSGrid mdxType="CSSGrid">
      <Col col={1 / 8} media={{
            phone: 1
          }} mdxType="Col"></Col>
      <Col col={6 / 8} media={{
            phone: 1
          }} mdxType="Col">
        <h5>Export ✈️</h5>
        <p>To help accomadate for the array of email clients in use by our team members, we needed a way to help explain and export their new signature for each of the popular email platforms.</p>
        <p>While we provided the export for each client, we also wanted to link the import tutorials provided by the help centers from each of the email cleints.</p>
      </Col>
      <Col col={1 / 8} media={{
            phone: 1
          }} mdxType="Col"></Col>
    </CSSGrid>
  </Container>
    </Section>
    <Text center marginBottom={designTokens.space[3]} size={designTokens.fontSizes[0]} color={({
      theme
    }) => theme.grey500} mdxType="Text">Allow users to export for multiple clients</Text>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "960px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "69.58333333333333%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAYAAAAvxDzwAAAACXBIWXMAAAsTAAALEwEAmpwYAAAB1ElEQVQ4y5WUzW7UMBSF5wUBsWQPSLwCOzpddQOiCJUVQkIIHqOVkMoKNOqO0k6aJkMaGufHv8kkh3s9SZUBhgFLR3au48/H98aZSCnBUkqhqiqvIfavorUdr6dxNxmCxhjMZjMkSYKuA5xzqOua1PT972qaBtZaD2Iggydjh0VRIApDxKRgHkDTJtsaQ9eA/cBD2ZXJBaqrBDLP/e4cG2sc48ZONzpkBeRufhEiSVMUZQkhhHcuRO7HWZYhp81YG4GDQ8t5YwdlgSXF6O2tR94MpOo6TrSmibNTqPNv0GGAbrlcA3RUsUFbHRpyJ+hIWRQhi2Pf80ZS6T4tGm3beljroduOTFVtnMGycWhqTr6BqQqoUkCWVCQt/1DlvwC1sVjkDpGwXldljTBMcPI1wsl5grPvJWLhcHltcJmtVCnrQVL+UmVDufshJO7tfcTtnUPcenKIB8+P8fjNF9x/doxH+5/wkJ7v7h7d6M70CJ9PU7S16apKjhwyVGuYIsf16z2kBztIX+2Spsje7SN//wLiw0uIt099bDVHOphCBVRAV3ec63UgHVuT7OICJprDxPO+D2A4tghX49Gc5Z4+MSpap/o77W8KwwaotO7/tFp783P4Cb81IYaSGlh+AAAAAElFTkSuQmCC')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Email Signature Export",
          "title": "Email Signature Export",
          "src": "/static/ba4646ffd79da908ce22d600544c9279/d9199/cb911-email-4.png",
          "srcSet": ["/static/ba4646ffd79da908ce22d600544c9279/8ff5a/cb911-email-4.png 240w", "/static/ba4646ffd79da908ce22d600544c9279/e85cb/cb911-email-4.png 480w", "/static/ba4646ffd79da908ce22d600544c9279/d9199/cb911-email-4.png 960w", "/static/ba4646ffd79da908ce22d600544c9279/07a9c/cb911-email-4.png 1440w", "/static/ba4646ffd79da908ce22d600544c9279/e216b/cb911-email-4.png 1904w"],
          "sizes": "(max-width: 960px) 100vw, 960px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <br />
    <br />
    <CallToAction mdxType="CallToAction" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      